import React, { useState, useEffect } from "react";
import { navigate } from 'gatsby';
import queryString from "query-string";

import AdEngageLayout from "components/layout";
import BrandOverview from "components/brand_overview";
import { BrandData } from "models/brand";

import { adEngagePath } from "utils/constants";
import { fetchBrandData, fetchCategoryData } from "utils/ad_engage";
import { getBrandDataFromPath, getCategoryMetadataFromPath } from "utils/jsonUtils";
import { CategoryData } from "models/category";

export interface TopBrandProps {
  category?: string;
  brand?: string;
  detail?: string;
}

const BrandOverviewPublicAdEngage: React.FC<TopBrandProps> = ({ category, brand, detail }) => {
  const { brandId, brandName } = getBrandDataFromPath(brand);
  const { categoryId, categoryName } = getCategoryMetadataFromPath(category);

  const [brandData, setBrandData] = useState<BrandData>();
  const [categoryData, setCategoryData] = useState<CategoryData>();
  const [fromTopBrands, setFromTopBrands] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    /*
      fetch Brand and Category Data
      both need to resolve for the .then() to be called
    */
    Promise.all([fetchBrandData(brandId), fetchCategoryData(categoryId)])
      .then(([brandData, categoryData]) => {
        setBrandData(brandData);
        setCategoryData(categoryData)
        setLoading(false);
      })
      .catch((err) => {
        // TODO: Report Error
        // TODO: Retry Fetch

        // redirect back to parent category if no data for brand
        navigate(adEngagePath + "/" + category);
        setLoading(false);
      });

    if (typeof window != "undefined") {
      setFromTopBrands(queryString.parse(location?.search)?.fromTopBrands === "true")
    }
  }, [brand, category]);

  return (
    <AdEngageLayout pageTitle={brandData?.brand_data?.brand_name ? brandData?.brand_data?.brand_name + " - Brand Overview" : "Brand Overview"}>
      <BrandOverview
        brand={{ brandId, brandName }}
        category={{ categoryId, categoryName }}
        detail={detail}
        brandData={brandData}
        categoryData={categoryData}
        fromTopBrands={fromTopBrands}
        loading={loading}
      />
    </AdEngageLayout>
  );
};

export default BrandOverviewPublicAdEngage;
